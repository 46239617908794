
import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

export default function Blogs({ blogs }) {
    return <section 
    className="section" 
    id="blogs"
    style={{
        backgroundImage: "url(https://i.imgur.com/50rjSlk.png)",
        backgroundRepeat: "no-repeat",
    }}
    >
        <div className="blogContent">
            <div className="sectionHeader">
                <div className="sectionTitle">
                    Our Blogs
                </div>
            </div>
            <div className="blogs">
                {blogs.map(({node: blog}) => {
                    const image = getImage(blog.frontmatter.thumbnail)
                    return <Link to={blog.frontmatter.path} key={blog.frontmatter.title} className="blog">
                        <div className="titleImg">
                            <GatsbyImage
                                image={image}
                                alt={blog.frontmatter.title}
                            />
                        </div>
                        <div className="textContainer">
                            <h3>{blog.frontmatter.title}</h3>
                        </div>
                    </Link>
                })}
            </div>
            <div className="blogLink" >
                <Link to="/blogs">See all blogs</Link>
            </div>
        </div>
    </section>
}